import React from "react";
import QuoteForm from "./QuoteForm";

export default function QuoteSection() {
  return (
    <div className="mx-auto max-w-xl lg:max-w-4xl">
      <h2 className="text-4xl text-center font-bold tracking-tight text-gray-900">
        Get a Fast & Free Quote Today!
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 pt-8 lg:pt-12">
        <div className="order-last lg:order-first pt-8 lg:pt-0">
          <h5 className="text-3xl leading-8 font-semibold">
            Why We're the Best
          </h5>
          <ul className="py-4 pr-8 text-lg list-disc">
            <li className="py-2">
              J-N-C Transport Services has over 25 years of experience
              transporting valuable cargo across the country.
            </li>
            <li className="py-2">
              Our number one priority is the safe handling of your cargo.
            </li>
            <li className="py-2">
              Our drivers have CDL experience and have been recongized with
              multiple safety awards.
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-16 sm:gap-y-20 lg:flex-row">
          <QuoteForm />
        </div>
      </div>
    </div>
  );
}
