import React from "react";
import data from "../../data";
import "./JumboImage.css";

export default function JumboImage() {
  return (
    <div>
      <div className="w-full overflow-hidden jumbo-image relative drop-shadow-xl">
        <div className="absolute z-10 text-white lg:text-4xl text-2xl font-semibold h-full w-full my-auto">
          <div className="text-center top-[15%]">
            <img className="mx-auto md:h-48 h-32" src="JNC_Logo.png"/>
            <h3 className="text-5xl font-bold mb-6">
              <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>
            </h3>
            <h3 className="text-4xl font-bold mb-2 min-[530px]:block hidden">
              The Best RV, Marine, and Cargo Transport
            </h3>
            <h4 className="text-xl hidden sm:block">
              For all your Non-DOT transport needs
            </h4>
          </div>
        </div>
        <video
          autoPlay
          muted
          loop
          playsInline
          className="w-full transform xl:-translate-y-72 brightness-[.33] z-0"
        >
          <source src="./traffic.mp4" type="video/mp4" />
        </video>
      </div>
      <h3 className="text-4xl font-bold mb-2 min-[530px]:hidden block text-gray-900 text-center mt-6">
        The Best RV, Marine, and Cargo Transport
      </h3>
    </div>
  );
}
