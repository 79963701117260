import "./src/styles/global.css";
import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import { Footer } from "./src/components";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
    <div>
      <div className="h-4 bg-zinc-800"></div>
      {element}
      <Footer />
    </div>
  );
};
