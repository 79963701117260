import React from "react";

export default function QuoteForm() {
  return (
    <form
      action="https://getform.io/f/5d11cf95-2cce-4c4a-b020-ba27acccb808"
      method="POST"
      className="lg:flex-auto"
    >
      <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <label
            htmlFor="name"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Name
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="name"
              id="name"
              autoComplete="name"
              className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Email
          </label>
          <div className="mt-2.5">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="phoneNumber"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Phone Number
          </label>
          <div className="mt-2.5">
            <input
              type="tel"
              name="phoneNumber"
              id="phoneNumber"
              autoComplete="tel-national"
              className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="itemToHaul"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            What needs moving?
          </label>
          <div className="mt-2.5">
            <select
              name="itemToHaul"
              id="itemToHaul"
              className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option disabled selected hidden>
                Select...
              </option>
              <option>Motor Home</option>
              <option>5th Wheel</option>
              <option>Travel Trailer</option>
              <option>Boat</option>
              <option>General Freight</option>
            </select>
          </div>
        </div>
        <div>
          <label
            htmlFor="origin"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Origin
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="origin"
              id="origin"
              className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="destination"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Destination
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="destination"
              id="destination"
              className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="additionalInfo"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Additional Information
          </label>
          <div className="mt-2.5">
            <textarea
              id="additionalInfo"
              name="additionalInfo"
              rows={4}
              className="block w-full rounded-md border-0 py-2 px-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={""}
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <button
          type="submit"
          className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Request a Quote
        </button>
      </div>
    </form>
  );
}
