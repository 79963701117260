import React from "react";
import data from "../data";

export default function Footer() {
  return (
    <div className="bg-zinc-800">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-4">
        <div className="text-center text-white font-semibold">
          <p>J-N-C Transportation</p>
          <p>{data.phoneNumber}</p>
          <p>{data.email}</p>
          <br />
          <p className="text-xs">
            Made with ❤️ by{" "}
            <a href="https://github.com/ethanbdx">Ethan Boudreaux</a>
          </p>
        </div>
      </div>
    </div>
  );
}
